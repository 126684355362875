import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="cookies"
export default class extends Controller {
  static targets = [ "banner" ]

  setConsent() {
    document.cookie = "cookie_consent=true";
    this.bannerTarget.style.display = "none"
  }
}