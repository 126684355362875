import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="trix-no-attachment"
export default class extends Controller {
  connect() {
    this.element.addEventListener("trix-file-accept", e => {
      e.preventDefault()
    })
    
  }
}
