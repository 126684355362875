import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-dropdown-chevron"
export default class extends Controller {
  static targets = ["toggle"]
  
  toggle() {
    this.toggleTarget.classList.toggle("bi-chevron-down")
    this.toggleTarget.classList.toggle("bi-chevron-up")
  }
}
